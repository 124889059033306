<template>
  <div>
    <PreLoader v-if="isLoading" />

    <CustomBottomSheet
      refName="FollowUpSettingAdd"
      size="xl"
      :headerText="$t('FollowUpSettings.addOrUpdate')"
      :headerIcon="followUpSetting.icon"
      :clickToClose="false"
      :swipeAble="false"
      @opened="gitDialog"
    >
      <FollowUpSettingForm
        v-if="!isLoading"
        id="add"
        :followUpSetting="followUpSetting"
        :userStudentOptions="userStudentOptions"
        :educationalCategoryTokenOptions="educationalCategoryTokenOptions"
        v-on:submitForm="addFollowUpSetting()"
        bottomSheetName="FollowUpSettingAdd"
        :submitName="$t('add')"
      />
    </CustomBottomSheet>
  </div>
</template>

<script>
import PreLoader from "./../../../../components/general/PreLoader.vue";
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import FollowUpSettingForm from "./FollowUpSettingForm.vue";
import { STATUS } from "./../../../../utils/constants";
import apiFollowUpSetting from "./../../../../api/followUps/followUpSettings";
import generalMixin from "./../../../../utils/generalMixin";
import {
  getStudentsFollowUpSettingsDialog,
  getEducationalCategoriesFollowUpSettingsDialog,
} from "./../../../../utils/dialogsOfApi";
import { USER_TYPE } from "./../../../../utils/constantLists";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    CustomBottomSheet,
    FollowUpSettingForm,
  },
  props: {
    followUpSetting: { type: Object },
  },
  data() {
    return {
      userStudentOptions: [],
      educationalCategoryTokenOptions: [],
    };
  },
  methods: {
    async gitDialog() {
      await this.getStudentsDialog();
      await this.getEducationalCategoriesDialog();
    },
    async getStudentsDialog() {
      this.isLoading = true;
      this.userStudentOptions = [];
      let params = {
        userTypeToken: USER_TYPE.Student,
        // notInFollowUpSettingsUserToken: this.followUpSetting.userSalesToken,
      };
      const [options, error] = await getStudentsFollowUpSettingsDialog(params);
      if (!error) this.userStudentOptions = options;
      this.isLoading = false;
    },
    async getEducationalCategoriesDialog() {
      this.isLoading = true;
      const [options, error] =
        await getEducationalCategoriesFollowUpSettingsDialog();
      if (!error) this.educationalCategoryTokenOptions = options;
      this.isLoading = false;
    },

    async addFollowUpSetting() {
      this.isLoading = true;
      try {
        const response = await apiFollowUpSetting.add(this.followUpSetting);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$emit("refresh");
          this.closeBottomSheet("FollowUpSettingAdd");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
};
</script>
