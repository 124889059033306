<template>
  <div class="">
    <DashboardNavbar
      :btnAddTitle="$t('FollowUpSettings.addOrUpdate')"
      :btnAddName="'FollowUpSettingAdd'"
      :btnAddStatus="checkPrivilege(hasFollowUpSettingAdd())"
    />
    <PreLoader v-if="isLoading" />

    <div class="app-content pagination-with-content">
      <template v-if="hasData">
        <div class="statistics">
          <div class="my-card">
            <UserSalesTable
              :userSalesInfo="
                followUpSettings.userSalesFollowUpSetting.userSalesInfo
              "
              :defaultImg="followUpSettings.userSalesFollowUpSetting.defaultImg"
              :filterData="followUpSettings.filterData"
            />
          </div>
        </div>

        <div class="statistics">
          <b-button
            v-b-toggle.UserSalesWithStudentsSettingsTable
            class="btn btn-lg btn-collapse no-margin"
          >
            {{ $t("students.modelName") }}
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </b-button>
          <b-collapse visible id="UserSalesWithStudentsSettingsTable">
            <div class="my-card no-margin">
              <div
                v-if="
                  !followUpSettings.userSalesFollowUpSetting
                    .userSalesWithStudentsSettingsData.length
                "
                class="text-center"
              >
                {{ $t("Login.noData") }}
              </div>
              <UserSalesWithStudentsSettingsTable
                v-else
                :userSalesWithStudentsSettingsData="
                  followUpSettings.userSalesFollowUpSetting
                    .userSalesWithStudentsSettingsData
                "
                :defaultImg="defaultImgStudents"
              />
            </div>
          </b-collapse>
        </div>

        <div class="statistics">
          <b-button
            v-b-toggle.UserSalesWithEducationalCategoriesSettingsTable
            class="btn btn-lg btn-collapse no-margin"
          >
            {{ $t("EducationalCategories.modelName") }}
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </b-button>
          <b-collapse
            visible
            id="UserSalesWithEducationalCategoriesSettingsTable"
          >
            <div class="my-card no-margin">
              <div
                v-if="
                  !followUpSettings.userSalesFollowUpSetting
                    .userSalesWithEducationalCategoriesSettingsData.length
                "
                class="text-center"
              >
                {{ $t("Login.noData") }}
              </div>
              <UserSalesWithEducationalCategoriesSettingsTable
                v-else
                :userSalesWithEducationalCategoriesSettingsData="
                  followUpSettings.userSalesFollowUpSetting
                    .userSalesWithEducationalCategoriesSettingsData
                "
                :defaultImg="defaultImgEducationalCategories"
              />
            </div>
          </b-collapse>
        </div>
      </template>
      <ExceptionWithImg
        v-if="exceptionMsg"
        :msg="exceptionMsg"
        :image="exceptionImg"
      />

      <FollowUpSettingAdd
        :followUpSetting="followUpSettings.followUpSetting"
        v-on:refresh="getUserSalesFollowUpSettingDetails()"
      />
    </div>
  </div>
</template>

<script>
import DashboardNavbar from "./../../../layouts/components/DashboardNavbar.vue";
import PreLoader from "./../../../components/general/PreLoader.vue";
import ExceptionWithImg from "./../../../components/general/ExceptionWithImg.vue";
import FollowUpSettingAdd from "./components/FollowUpSettingAdd.vue";
import UserSalesTable from "./components/UserSalesTable.vue";
import UserSalesWithStudentsSettingsTable from "./components/UserSalesWithStudentsSettingsTable.vue";
import UserSalesWithEducationalCategoriesSettingsTable from "./components/UserSalesWithEducationalCategoriesSettingsTable.vue";
import FollowUpSettings from "./../../../models/followUps/followUpSettings/FollowUpSettings";
import apiFollowUpSetting from "./../../../api/followUps/followUpSettings";
import { STATUS } from "./../../../utils/constants";
import generalMixin from "./../../../utils/generalMixin";
import defaultImgStudents from "@/assets/images/students.svg";
import defaultImgEducationalCategories from "@/assets/images/EducationalCategories.svg";
import { checkPrivilege } from "./../../../utils/functions";
import { hasFollowUpSettingAdd } from "./../../../utils/privilegeHelper";

export default {
  name: "FollowUpSettings",
  mixins: [generalMixin],
  components: {
    DashboardNavbar,
    PreLoader,
    UserSalesTable,
    UserSalesWithStudentsSettingsTable,
    UserSalesWithEducationalCategoriesSettingsTable,
    ExceptionWithImg,
    FollowUpSettingAdd,
  },
  props: {
    token: {
      type: String,
    },
  },
  computed: {},
  data() {
    return {
      followUpSettings: new FollowUpSettings(),
      hasData: false,
      defaultImgStudents,
      defaultImgEducationalCategories,
    };
  },
  methods: {
    checkPrivilege,
    hasFollowUpSettingAdd,

    setFollowUpSetting(userSalesFollowUpSetting) {
      let userSalesToken = userSalesFollowUpSetting.userSalesInfo.userToken;

      let userStudentTokens =
        userSalesFollowUpSetting.userSalesWithStudentsSettingsData.map(
          (t) => t.userStudentToken
        );
      let educationalCategoryTokens =
        userSalesFollowUpSetting.userSalesWithEducationalCategoriesSettingsData.map(
          (t) => t.educationalCategoryToken
        );
      let followUpSetting = {
        userSalesToken: userSalesToken,
        userStudentTokens: userStudentTokens,
        educationalCategoryTokens: educationalCategoryTokens,
      };
      this.followUpSettings.followUpSetting.fillData(followUpSetting);
    },

    async getUserSalesFollowUpSettingDetails() {
      this.isLoading = true;
      this.hasData = false;
      try {
        this.followUpSettings.userSalesFollowUpSetting.setInitialValue();
        const response = await apiFollowUpSetting.getDetails({
          token: this.token,
        });
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.setFollowUpSetting(response.data.userSalesFollowUpSetting);
          this.hasData = true;
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noInternet.svg";
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.followUpSettings.userSalesFollowUpSetting.fillData(
          response.data.userSalesFollowUpSetting
        );
      } catch (e) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.followUpSettings.followUpSetting.userSalesToken = this.token;
    this.getUserSalesFollowUpSettingDetails();
  },
  watch: {},
};
</script>
