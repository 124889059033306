<template>
  <div v-if="userStudentOptions.length" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th rowspan="2">
            <input
              v-if="userStudentOptions.length"
              type="checkbox"
              id="selectAll-userStudentOptions"
              @click="selectAll()"
              v-model="selectedAll"
              class="checkbox"
            />
            {{ $t("general.all") }}
          </th>
          <th colspan="4">{{ $t("students.data") }}</th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>
          <th>{{ $t("Users.phone") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in userStudentOptions" :key="item.value">
          <td>{{ ++index }}</td>
          <td>
            <input
              type="checkbox"
              v-model="followUpSetting.userStudentTokens"
              :value="item.value"
              @click="select(item.value)"
              class="checkbox"
            />
          </td>
          <td>
            <img
              class="item-img-table"
              :src="item.image"
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>{{ isDataExist(item.fullCode) }}</td>
          <td>
            {{ isDataExist(item.userNameCurrent) }}
          </td>
          <td class="table-phone-number">
            {{ isDataExist(item.phone) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { isDataExist } from "./../../../../utils/functions";

export default {
  name: "StudentsFollowUpSettingsTable",
  mixins: [],
  components: {},
  props: {
    followUpSetting: { type: Object },
    userStudentOptions: {
      type: Array,
      default: () => [],
    },
    defaultImg: {
      type: String,
    },
  },

  data() {
    return {
      selectedAll: false,
    };
  },
  computed: {},
  watch: {
    // userStudentOptions: function () {
    //   for (let ietm in this.userStudentOptions) {
    //     this.select(ietm);
    //   }
    // },
  },
  async created() {
    for (let ietm in this.userStudentOptions) {
      await this.select(ietm);
    }
  },
  methods: {
    isDataExist,
    selectAll() {
      // this.filterTable = "";
      this.followUpSetting.userStudentTokens = [];
      this.selectedAll = !this.selectedAll;
      if (this.selectedAll) {
        for (let ietm in this.userStudentOptions) {
          this.followUpSetting.userStudentTokens.push(
            this.userStudentOptions[ietm].value
          );
        }
      }
    },
    async select(value) {
      const indexItem = this.followUpSetting.userStudentTokens.indexOf(value);

      if (indexItem > -1) {
        this.followUpSetting.userStudentTokens.splice(indexItem, 1);
      }
      setTimeout(() => {
        if (
          this.userStudentOptions.length ==
          this.followUpSetting.userStudentTokens.length
        ) {
          this.selectedAll = true;
        } else {
          this.selectedAll = false;
        }
      }, 1000);
    },

    // },
  },
};
</script>
