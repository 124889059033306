<template>
  <div v-if="educationalCategoryTokenOptions.length" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th rowspan="2">
            <input
              v-if="educationalCategoryTokenOptions.length"
              type="checkbox"
              id="selectAll-educationalCategoryTokenOptions"
              @click="selectAll()"
              v-model="selectedAll"
              class="checkbox"
            />
            {{ $t("general.all") }}
          </th>
          <th colspan="3">{{ $t("EducationalCategories.data") }}</th>
          <th rowspan="2">
            {{ $t("SystemComponentsHierarchies.educationalActivity") }}
          </th>
          <th rowspan="2">{{ $t("SystemComponents.educationalActivity") }}</th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in educationalCategoryTokenOptions"
          :key="item.value"
        >
          <td>{{ ++index }}</td>
          <td>
            <input
              type="checkbox"
              v-model="followUpSetting.educationalCategoryTokens"
              :value="item.value"
              @click="select(item.value)"
              class="checkbox"
            />
          </td>
          <td>
            <img
              class="item-img-table"
              :src="item.image"
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>{{ isDataExist(item.fullCode) }}</td>
          <td>
            {{ isDataExist(item.educationalCategoryNameCurrent) }}
          </td>
          <td>
            {{ isDataExist(item.systemComponentsHierarchyNameCurrent) }}
          </td>
          <td>
            {{ isDataExist(item.systemComponentNameCurrent) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { isDataExist } from "./../../../../utils/functions";

export default {
  name: "EducationalCategoriesFollowUpSettingsTable",
  mixins: [],
  components: {},
  props: {
    followUpSetting: { type: Object },
    educationalCategoryTokenOptions: {
      type: Array,
      default: () => [],
    },
    defaultImg: {
      type: String,
    },
  },

  data() {
    return {
      selectedAll: false,
    };
  },
  computed: {},
  watch: {},
  async created() {
    for (let ietm in this.educationalCategoryTokenOptions) {
      await this.select(ietm);
    }
  },
  methods: {
    isDataExist,
    selectAll() {
      // this.filterTable = "";
      this.followUpSetting.educationalCategoryTokens = [];
      this.selectedAll = !this.selectedAll;
      if (this.selectedAll) {
        for (let ietm in this.educationalCategoryTokenOptions) {
          this.followUpSetting.educationalCategoryTokens.push(
            this.educationalCategoryTokenOptions[ietm].value
          );
        }
      }
    },
    async select(value) {
      const indexItem =
        this.followUpSetting.educationalCategoryTokens.indexOf(value);

      if (indexItem > -1) {
        this.followUpSetting.educationalCategoryTokens.splice(indexItem, 1);
      }
      setTimeout(() => {
        if (
          this.educationalCategoryTokenOptions.length ==
          this.followUpSetting.educationalCategoryTokens.length
        ) {
          this.selectedAll = true;
        } else {
          this.selectedAll = false;
        }
      }, 1000);
    },

    // },
  },
};
</script>
