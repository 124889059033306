<template>
  <div
    v-if="userSalesWithEducationalCategoriesSettingsData.length"
    class="table-container"
  >
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="3">{{ $t("EducationalCategories.data") }}</th>
          <th rowspan="2">
            {{ $t("SystemComponentsHierarchies.educationalActivity") }}
          </th>
          <th rowspan="2">{{ $t("SystemComponents.educationalActivity") }}</th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(
            educationalCategory, index
          ) in userSalesWithEducationalCategoriesSettingsData"
          :key="educationalCategory.educationalCategoryToken"
        >
          <td>{{ ++index }}</td>
          <td>
            <img
              class="item-img-table"
              :src="
                fullPathFileFromServer(
                  educationalCategory.educationalCategoryInfoData
                    .educationalCategoryFullImagePath,
                  defaultImg
                )
              "
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>
            {{
              isDataExist(
                educationalCategory.educationalCategoryInfoData.fullCode
              )
            }}
          </td>
          <td>
            {{
              isDataExist(
                educationalCategory.educationalCategoryInfoData
                  .educationalCategoryNameCurrent
              )
            }}
          </td>
          <td>
            {{
              isDataExist(
                educationalCategory.educationalCategoryInfoData
                  .systemComponentHierarchyNameCurrent
              )
            }}
          </td>
          <td>
            {{
              isDataExist(
                educationalCategory.educationalCategoryInfoData
                  .systemComponentNameCurrent
              )
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  isDataExist,
  checkPrivilege,
  fullPathFileFromServer,
} from "./../../../../utils/functions";

export default {
  name: "UserSalesWithEducationalCategoriesSettingsTable",
  mixins: [],
  components: {},
  props: {
    userSalesWithEducationalCategoriesSettingsData: {
      type: Array,
      default: () => [],
    },
    defaultImg: {
      type: String,
    },
  },

  data() {
    return {};
  },
  computed: {},
  methods: {
    isDataExist,
    checkPrivilege,
    fullPathFileFromServer,
  },
  async created() {},
};
</script>
