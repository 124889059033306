var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('DashboardNavbar',{attrs:{"btnAddTitle":_vm.$t('FollowUpSettings.addOrUpdate'),"btnAddName":'FollowUpSettingAdd',"btnAddStatus":_vm.checkPrivilege(_vm.hasFollowUpSettingAdd())}}),(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('div',{staticClass:"app-content pagination-with-content"},[(_vm.hasData)?[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"my-card"},[_c('UserSalesTable',{attrs:{"userSalesInfo":_vm.followUpSettings.userSalesFollowUpSetting.userSalesInfo,"defaultImg":_vm.followUpSettings.userSalesFollowUpSetting.defaultImg,"filterData":_vm.followUpSettings.filterData}})],1)]),_c('div',{staticClass:"statistics"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.UserSalesWithStudentsSettingsTable",modifiers:{"UserSalesWithStudentsSettingsTable":true}}],staticClass:"btn btn-lg btn-collapse no-margin"},[_vm._v(" "+_vm._s(_vm.$t("students.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"visible":"","id":"UserSalesWithStudentsSettingsTable"}},[_c('div',{staticClass:"my-card no-margin"},[(
                !_vm.followUpSettings.userSalesFollowUpSetting
                  .userSalesWithStudentsSettingsData.length
              )?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("Login.noData"))+" ")]):_c('UserSalesWithStudentsSettingsTable',{attrs:{"userSalesWithStudentsSettingsData":_vm.followUpSettings.userSalesFollowUpSetting
                  .userSalesWithStudentsSettingsData,"defaultImg":_vm.defaultImgStudents}})],1)])],1),_c('div',{staticClass:"statistics"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.UserSalesWithEducationalCategoriesSettingsTable",modifiers:{"UserSalesWithEducationalCategoriesSettingsTable":true}}],staticClass:"btn btn-lg btn-collapse no-margin"},[_vm._v(" "+_vm._s(_vm.$t("EducationalCategories.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"visible":"","id":"UserSalesWithEducationalCategoriesSettingsTable"}},[_c('div',{staticClass:"my-card no-margin"},[(
                !_vm.followUpSettings.userSalesFollowUpSetting
                  .userSalesWithEducationalCategoriesSettingsData.length
              )?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("Login.noData"))+" ")]):_c('UserSalesWithEducationalCategoriesSettingsTable',{attrs:{"userSalesWithEducationalCategoriesSettingsData":_vm.followUpSettings.userSalesFollowUpSetting
                  .userSalesWithEducationalCategoriesSettingsData,"defaultImg":_vm.defaultImgEducationalCategories}})],1)])],1)]:_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg,"image":_vm.exceptionImg}}):_vm._e(),_c('FollowUpSettingAdd',{attrs:{"followUpSetting":_vm.followUpSettings.followUpSetting},on:{"refresh":function($event){return _vm.getUserSalesFollowUpSettingDetails()}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }